import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar'
import { FaQuestion, FaMoon, FaRunning, FaHandPointRight, FaHandPointLeft, FaRegHandPointRight, FaRegHandPointLeft } from "react-icons/fa";
import { format, addMonths, addDays, subDays } from 'date-fns';

const Sched = (farmno) => {
  // Calendar processing
  const [dateState, setDateState] = useState(new Date())  // current day
  const [calstat, setCalStat] = useState()
  // Calander data.
  const [cherrycal, setCal] = useState(null)
  
   // fire once. auto-refresh disabled
  const refreshSched = () => {
    // reload the calendar data 
    console.log("farmno", farmno.farmno);
    setCalStat(`Farm ${farmno.farmno} refreshed at ${format(new Date().toLocaleString(), 'eee HH:mm:ss') }`);
    fetch(`https://ctrl2.cherry2024.com:2096/cherrycal?farmno=${farmno.farmno}`)
      .then(response => response.json())
      .then(cherrycal => setCal(cherrycal))
      .catch(error => console.error(error));
  }

  useEffect(() => {
    refreshSched(); 
  }, []);

  const changeDate = (e) => {
    if (e > new Date()) {
      setDateState(e)
      // update sched
      var clickeddate = new Date(e);
      var dayin = daysIntoYear(clickeddate);
      var clickedyear = clickeddate.getFullYear();;
      var onoff = 1;
      for (var d = 0; d < cherrycal.length; d++) {
        if (cherrycal[d].schedday === dayin) {
          onoff = cherrycal[d].schedon = Math.abs(cherrycal[d].schedon - 1);
        }
      }
      // add a calendar rec. we don't car about the caheerycal data coming back.
      // refresh sched will clean everything up
      fetch(`https://ctrl2.cherry2024.com:2096/cherryschedadd?farmno=${farmno.farmno}&year=${clickedyear}&dayno=${dayin}&onoff=${onoff}`)
        .then(response => response.json())
        .then(cherrycal => {
          refreshSched();         
         })
        .catch(error => console.error(error));
    }
  };

  function daysIntoYear(date) {
    return (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
  }
  // Content
  function tileContent({ date, view }) {
    // Add class to tiles in month view only
    if (view === 'month') {
      var newtile = null;
      if (date >= subDays(new Date(), 1)) {
        var curdate = new Date(date);
        var dayin = daysIntoYear(curdate);
        newtile = <span className='calicon'>< FaQuestion /></span>;
        for (var d = 0; d < cherrycal.length; d++) {
          if (cherrycal[d].schedday === dayin) {
            if (cherrycal[d].schedon) {
              newtile = <sup className='calicon'>< FaRunning /></sup>;
            } else {
              newtile = <sub className='calicon'><FaMoon /></sub>;
            }
          }
        }
      };
      return newtile;
    }
  }

  // Style
  function tileClassName({ date, view }) {
    // Add class to tiles in month view only
    if (view === 'month') {
      // Check if a date React-Calendar wants to check is on the list of dates to add class to
      var addedClass = "caldone";
      if (date >= subDays(new Date(), 1)) {
        addedClass = "calnot";
        var curdate = new Date(date);
        var dayin = daysIntoYear(curdate);
        for (var d = 0; d < cherrycal.length; d++) {
          if (cherrycal[d].schedday === dayin) {
            if (cherrycal[d].schedon) {
              addedClass = "calon";
            } else {
              addedClass = "caloff";
            }
          }
        }
      }
      return addedClass;
    }
  }

  return (
    <div>
      {cherrycal ?
        <div className="container">
          <div className="row">
            <span className="col-sm-12 d-flex align-items-center justify-content-center">
              <Calendar
                value={dateState}
                onChange={changeDate}
                tileClassName={tileClassName}
                tileContent={tileContent}
                className={"calmain"}
                minDetail={"month"}
                nextLabel={<FaRegHandPointRight />}
                prevLabel={<FaRegHandPointLeft />}
                minDate={subDays(new Date(), 3)}
                maxDate={addMonths(new Date(), 4)}
                next2Label={null}
                prev2Label={null}
              />
            </span>
          </div>
          <div className="row">
            <span className="justify-content-center col-sm-12">
              {(calstat) &&
                <span className="d-flex align-items-center justify-content-center shorttext ">
                  {calstat}
                </span>
              }
            </span>
          </div>
        </div>
        :
        <span>&nbsp;&nbsp;Loading Calendar. One Moment...</span>

      }
    </div>
  )

}

export default Sched;
